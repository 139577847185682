<template>
	<div class="infoBox">
		<div :class="['info',location == 'left' ? 'left':'right',infoStatus?'active':'',]">
			<div :class="['switch',location == 'left' ? 'left':'right']" @click="switchStatus('infoStatus')">
				{{switchText}}
			</div>
			<div :class="[infoStatus?'show':'hide']">
				<slot name="content"></slot>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'infoBox',
		props: ['location'],
		data() {
			return {
				infoStatus: true,
			}
		},
		computed: {
			switchText() {
				return this.infoStatus ? this.location == 'left' ? '<' : '>' : this.location == 'left' ? '>' : '<'
			}
		},
		methods: {
			switchStatus(status) {
				this[status] = !this[status]
			}
		},

	}
</script>

<style scoped lang="scss">
	.infoBox {
		font-size: 16px;
		margin-top: 20px;
		

		.info {
			border-radius: 8px;
			background-color: transparent;
			position: relative;
			font-size: 0;
			margin-left: 30px;
			box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.12); 
			

			&.left {
				width: 0;

				&.active {
					width: 570px;
				}
			}

			&.right {
				width: 0px;

				&.active {
					width: 140px;
				}
			}

			&.active {
				background-color: #FFFFFF;
				font-size: 16px;
				margin-left: 30px;
			}

			.switch {
				font-size: 16px;
				width: 20px;
				height: 60px;
				position: absolute;
				top: 30%;
				cursor: pointer;
				z-index: 9999;
				opacity: 1 !important;
				text-align: center;
				line-height: 60px;
				background: #f9f9f9;
				box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.20);
				color: #B3B3B3;
				font-weight: 500;

				&.left {
					right: -23px;
				}

				&.right {
					left: -23px;
				}
			}

			.show {
				opacity: 1;
			}

			.hide {
				opacity: 0;
				overflow: hidden;
			}
		}
	}
</style>
