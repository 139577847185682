<template>
	<bm-overlay ref="customOverlay" class="sample" pane="labelPane" @draw="draw">
		<div class='bmoverlay' :style="{'background':levelColor}">
			<div class='bmovebox' v-html="area+'<br/>'+number+'宗'" @click="handleClick(markObj)"></div>
			<div v-show="active" class="infoWindow">
				<p class="info "><span class="status"
						:style="{'background-color': showSelectType? '#398EFF': markObj.transactionStateCode == 2?'#2FC83D': markObj.transactionStateCode == 0?'#398EFF':'#FF0000'}"></span>{{showSelectType?'出让':markObj.transactionStateName}}
				</p>
				<p class="info">宗数：<span class="number">{{number}}</span>宗</p>
				<p v-if="markObj.transactionStateCode == '2' && !showSelectType" class="info">楼面价：<span
						class="number">{{markObj.floorPriceSum | numFormate}}</span> 元/m²</p>
				<p v-if="markObj.transactionStateCode == '2' && !showSelectType" class="info">成交价：<span class="number">
						{{markObj.transactionPriceSum | numFormate}}</span> 亿元</p>
				<p v-if="markObj.transactionStateCode != '2' || showSelectType" class="info">建筑面积：<span
						class="number">{{markObj.buildingAreaSum | numFormate}}</span>万m²</p>
				<p v-if="markObj.transactionStateCode != '2' || showSelectType" class="info">土地面积：<span class="number">
						{{markObj.landAreaSum | numFormate }}</span>万m²</p>
				<div class="trangle"></div>
			</div>
		</div>
	</bm-overlay>
</template>
<script>
	export default {
		props: ['position', 'markObj', 'level', 'area', 'number', 'active', 'zoom', 'selected'],
		watch: {
			position: {
				handler() {
					this.$refs.customOverlay.reload()
				},
				deep: true
			},
		},
		filters: {
			numFormate: function(val) {
				if (val) {
					return val.toFixed(2)
				} else {

					return 0.00;
				}
			}
		},
		computed: {
			levelColor() {
				if (this.level == 1) {
					return '#1050A1'
				} else if (this.level == 2) {
					return '#0068CF'
				} else if (this.level == 3) {
					return '#2AA3EE'
				} else {
					return '#71C2F4'
				}
			},
			showSelectType() {
				if(this.selected && this.selected.type == 0){
					return true
				}
				return false
			}
		},
		methods: {
			handleClick(e) {
				this.$emit('goCity', this.position, this.zoom, e)
			},
			draw({
				el,
				BMap,
				map
			}) {
				const {
					lng,
					lat
				} = this.position
				const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat))
				el.style.left = pixel.x - 40 + 'px'
				el.style.top = pixel.y - 40 + 'px'
			}
		}
	}
</script>
<style scoped lang="scss">
	.bmoverlay {
		position: relative;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		padding: 2px;
		display: flex;
		align-items: left;
		justify-content: space-around;
		flex-direction: column;
		transition: all .5s;
	}
	.bmoverlay:hover {
		transform: scale(1.1);
		background: orange !important;
		z-index: 99;
		
	}

	.bmovebox {
		cursor: pointer;
	}

	.sample {
		width: 60px;
		height: 60px;
		line-height: 20px;
		/* border: 3px solid rgba(224, 32, 32, 0.4); */
		box-shadow: 0 0 5px rgba($color: #000, $alpha: 0.3);
		color: #fff;
		/* 	display: flex;
		align-items: left;
		justify-content: space-around;
		flex-direction: column; */
		padding-top: 40px;
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		padding: 0px;
		position: absolute;
		top: 10px;
		border-radius: 50%;
		font-weight: 500;
		font-size: 12px;
	}

	.sample.active {
		background: rgba(186, 23, 18, 0.75);
		color: #fff;
	}

	.infoWindow {
		position: absolute;
		width: 250px;
		height: 150px;
		background-color: rgba($color: #ffffff, $alpha: 0.95);
		top: -180px;
		left: -70px;
		border-radius: 8px;
		z-index: 999;
		font-size: 16px;
		color: black;
		padding: 10px 25px;
		text-align: left;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.20); 

		.info {
			flex: 1;
			text-align: left;
			font-weight: 400;
			width: 100%;
			position: relative;

			.number {
				white-space: nowrap;
				width: 80px;
				display: inline-block;
				overflow: hidden;
				text-overflow: ellipsis;
				position: relative;
				top: 5px;
				text-align: center;
			}
		}

		.status {
			content: '';
			width: 10px;
			height: 10px;
			position: absolute;
			top: 5px;
			left: -15px;
			border-radius: 50%;
		}

		// #398EFF
		//#FF0000
		//#2FC83D

		.trangle {
			position: absolute;
			width: 0;
			height: 0;
			border-top: 20px solid white;
			border-right: 20px solid transparent;
			border-bottom: 0px solid transparent;
			border-left: 20px solid transparent;
			top: 150px;
			left: 80px;
		}
	}
</style>
