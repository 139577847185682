<template>
	<div class="checkBoxControl" :style="{width:`${width}px`}">
		<div class="checkBox">
			<!-- <CheckItem label='全选' color='#E97C65' @select='checkAll' @delete='deleteAll'>全选</CheckItem> -->
			<CheckGroup :group='group' @changeValue='getValue'>
				<CheckItem label='USE_RES' color='#719FF7'>住宅</CheckItem>
				<CheckItem label='USE_COMS' color='#F5C736'>商服</CheckItem>
				<CheckItem label='USE_IND' color='#6FDCB2'>工业</CheckItem>
				<CheckItem label='USE_OTHER' color='#7182A0'>其他</CheckItem>
			</CheckGroup>
		</div>
	</div>
</template>

<script>
	import CheckItem from './checkItem.vue'
	import CheckGroup from './CheckGroup.vue'
	export default {
		name: 'checkBoxControl',
		components: {
			CheckItem,
			CheckGroup
		},
		props: {
			//动态设置高度
			width: {
				type: [Number, String],
				default: 1640
			},
			group:{
				type:Array,
				default:()=>{
					return []
				},
			}
		},
		methods: {
			getValue(data){
				this.$emit('selectGruop',data)
			}
		},
	}
</script>

<style scoped lang="scss">
	.checkBoxControl {
		height: 60px;
		background-color: white;
	}

	.checkBox {
		width: 500px;
		position: relative;
		left: 50%;
		top: 20px;
		transform: translateX(-50%);		
	}
</style>
