<template>
	<div class="CheckGroup">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'CheckGroup',
		props: {
			group: {
				type: Array,
				required: true
			}
		},
		data() {
			return {
				currentValue: [],
				childrens:[],
			}
		},
		watch: {
			group(newValue) {
				this.currentValue = newValue
				this.updata()
			},
			currentValue(newValue){
				this.change(newValue)
			}
		},
		mounted(){
			this.updata()
		},
		methods: {
			updata() {
				let child = this.$children
				child.forEach(item => {
					this.group.forEach(value =>{
						if(item.label == value){
							item.checked = true
						}
					})
				})
			},
			change(data){
				this.$emit('changeValue',data)
			}
		},
	}
</script>

<style scoped lang="scss">
	.CheckGroup {
		display: inline-block;
	}
</style>
