<template>
	<div @click="checkedItem" class="checkItem">
		<span  class="item icon">
			<div class="colorCheck" :style="{'background':checked?color:'transparent'}"></div>
		</span>
        <span  class="item label">
			{{name}}
		</span>
	</div>
</template>

<script>
	export default {
		name: 'checkItem',
		props: {
			label: {
				type: [Number, String],
				default: 0
			},
            name: {
				type: String,
				default: ''
			},
            checked: {
				type: [Boolean, String],
				default: ''
			},
			color: {
				type: String,
				default: ''
			},
		},
		data() {
			return {
				currentValue: this.label,
			}
		},
		methods: {
			checkedItem() {
                let res = {
                    checked:!this.checked,
                    label:this.label
                }
                this.$emit('setValue',res)
			}
		},
	}
</script>

<style scoped lang="scss">
	.checkItem {
		display: inline-block;
		vertical-align: middle;
		margin: 0px 10px;

		.item {
			display: inline-block;
			vertical-align: middle;
			cursor: pointer;
		}

		.icon {
			width: 20px;
			height: 20px;
			border: 1px solid black;
			border-radius: 5px;
			padding: 3px;
			.colorCheck{
				width: 100%;
				height: 100%;
			}
		}

		.label {
			margin-left: 5px;
			font-weight: 600;
			font-size: 16px;
			color: #333333;
		}

	}
</style>
