<template>
  <bm-overlay ref="customOverlay" class="sample" pane="labelPane" @draw="draw">
    <div class="bmoverlay" >
      <div
        class="trangle"
        v-show="showBmovebox"
        :style="{ 'border-top-color': levelColor,opacity: opacitys }"
      ></div>
      <div
        class="mapPoint"
        :style="{ background: levelColor }"
      ></div>

      <Tooltip :content="area" placement="bottom" max-width="370"  v-show="showBmovebox">
        <div class="bmovebox" :style="{ background: levelColor,opacity: opacitys }" v-show="showBmovebox" @click.stop="showComponent">
          {{ area }}
        </div>
      </Tooltip>
      <div
        v-show="showInfoWindow"
        class="infoWindow"
        :style="{ top: positiontop, left: positionleft }"
      >
        <p class="close" @click.stop="hideSelect">X</p>
        <p class="title">
          <span
            :class="['star', markObj.isAttention ? 'active' : '']"
            @click.stop="focusLand(markObj)"
            :title="markObj.isAttention ? '取消关注' : '关注地块'"
          ></span>
          <span class="landName" :title="markObj.landName">{{
            markObj.landName
          }}</span>
        </p>
        <div class="detailBox">
          <div class="detail">
            <div class="_label">交易状态：</div>
            <div class="_info">
              {{ markObj.transactionStateName }}
              <span
                class="status"
                :style="{
                  'background-color':
                    markObj.transactionStateCode == 2
                      ? '#2FC83D'
                      : markObj.transactionStateCode == 0
                      ? '#398EFF'
                      : '#FF0000',
                }"
              ></span>
            </div>
          </div>
          <div class="detail">
            <div class="_label">土地用途：</div>
            <div class="_info">{{ markObj.landUseName }}</div>
          </div>
          <div class="detail">
            <div class="_label">出让时间：</div>
            <div class="_info">{{ markObj.transferDate | dataFormat }}</div>
          </div>
          <div class="detail">
            <div class="_label">楼面价：</div>
            <div class="_info">{{ markObj.floorPrice }}元/m²</div>
          </div>
          <div class="detail">
            <div class="_label">起始价：</div>
            <div class="_info">{{ markObj.startPrice }}万元</div>
          </div>
          <div class="detail">
            <div class="_label">建筑面积：</div>
            <div class="_info">{{ markObj.buildingArea }}m²</div>
          </div>
          <div class="detail">
            <div class="_label">土地面积：</div>
            <div class="_info">{{ markObj.landArea }}m²</div>
          </div>
          <div class="detail">
            <div class="_label">容积率：</div>
            <div class="_info">{{ markObj.volumeRate }}</div>
          </div>
          <div class="detail">
            <div class="_label">宗地编号：</div>
            <div class="_info" :title="markObj.landNumber">
              {{ markObj.landNumber }}
            </div>
          </div>
          <div class="detail">
            <div class="_label">出让方式：</div>
            <div class="_info">{{ markObj.transferWayName }}</div>
          </div>
          <br />
          <div class="detail">
            <div class="_label">地块位置：</div>
            <div class="_info" :title="markObj.landPosition">
              {{ markObj.landPosition }}
            </div>
          </div>
        </div>
        <div class="btnBox">
          <!-- 暂时屏蔽该功能 -->
          <!-- <Button size='small' type="primary" @click="handleAddProject">看项目</Button> -->
          <Button
            style="margin-left: 10px"
            size="small"
            type="info"
            @click.stop="goLandDetail"
            >详情</Button
          >
        </div>
        <div class="trangle"></div>
      </div>
    </div>
  </bm-overlay>
</template>
<script>
import { land_data_is_cancel_favorite } from "../../../api/reLand.js";
import util from "../../../utils/utils2.js";
import Vue from "vue";
var Event = new Vue(); //定义一个空的Vue实例
export default {
  props: ["area", "position", "active", "landUseCode", "markObj"],
  watch: {
    position: {
      handler() {
        this.$refs.customOverlay.reload();
      },
      deep: true,
    },
  },
  filters: {
    dataFormat(val) {
      if (val) {
        return util.formatDateFmt(new Date(val), "yyyy-MM-dd");
      } else {
        return "--";
      }
    },
  },
  computed: {
    levelColor() {
      if (this.landUseCode == "USE_RES") {
        return "#719FF7";
      } else if (this.landUseCode == "USE_COMS") {
        return "#F5C736";
      } else if (this.landUseCode == "USE_IND") {
        return "#6FDCB2";
      } else {
        return "#7182A0";
      }
    },
  },
  data() {
    return {
      showInfoWindow: false,
      focus: false,
      showBmovebox: true,
      positiontop: "-380px",
      positionleft: "-60px",
      x: 0,
      opacitys: 1,
      areas: '',
    };
  },
  mounted() {
    this.areas = this.area
    let self = this;
    Event.$on("info-closeAll", self.hideSelect); //监听到组件要求关闭的事件,事件名称自定义
    Event.$on("box-closeAll", self.showbox); //监听到组件要求关闭的事件,事件名称自定义
  },
  methods: {
    async focusLand(row) {
      row.isCancel = row.isAttention;
      row.isAttention = !row.isAttention;
      let data = await land_data_is_cancel_favorite(row);
      if (data) {
        this.$msg.success({
          text: row.isAttention ? "关注成功" : "取消关注成功",
        });
      }
    },
    hideSelect() {
      this.showInfoWindow = false;
      this.showBmovebox = true;
    },
    showbox() {
      let self = this;
      self.showBmovebox = true;
    },
    //多个展示infowindow
    handleClick() {
      // this.showInfoWindow = !this.showInfoWindow
      if (this.showInfoWindow == true) {
        this.showInfoWindow = false;
      } else {
        this.showInfoWindow = true;
      }
    },
    //单个展示infowindow
    showComponent() {
      let self = this;
      self.x += 1
      if (self.x == 1) {
        self.opacitys = 0.8
      }
      let showStore = self.showInfoWindow;
      let showBox = self.showBmovebox;
      Event.$emit("box-closeAll"); //通知其他各组件打开
      Event.$emit("info-closeAll"); //通知其他各组件关闭
      if (showStore) {
        //之前是已经打开的
        // self.positiontop = "-380px";
        // self.positionleft = "-60px";
        self.showInfoWindow = false;
      } else {
        self.positiontop = "-330px";
        self.positionleft = "-116px";
        self.showInfoWindow = true;
      }
      if (showBox) {
        self.showBmovebox = false;
      } else {
        self.showBmovebox = true;
      }
    },
    showhide() {
      let self = this;
      let showStore = self.showBmovebox;
      Event.$emit("box-closeAll"); //通知其他各组件打开
      Event.$emit("info-closeAll"); //通知其他各组件关闭
      if (showStore) {
        //之前是已经关闭的
        self.positiontop = "-330px";
        self.positionleft = "-116px";
        self.showBmovebox = false;
      } else {
        self.positiontop = "-380px";
        self.positionleft = "-60px";
        self.showBmovebox = true;
      }
      this.showComponent();
    },
    handleAddProject() {
      let res = {
        addProjectId: 123,
        address: {
          lng: this.position.lng,
          lat: this.position.lat,
        },
        projectName: this.markObj.area,
      };
      this.$emit("handleAddProject", res);
    },
    goLandDetail() {
      let res = this.markObj;
      this.$emit("goLandDetail", res);
    },
    draw({ el, BMap, map }) {
      const { lng, lat } = this.position;
      const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat));
      el.style.left = pixel.x + "px";
      el.style.top = pixel.y + "px";
    },
  },
};
</script>
<style scoped lang="scss">
.bmoverlay {
  position: relative;
  padding: 2px;
  display: flex;
  align-items: left;
  justify-content: space-around;
  flex-direction: column;
  // border-radius: 8px;
  top: -50px;
  left: -28px;
  // box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.12);
  .trangle {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid white;
    border-right: 10px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 10px solid transparent;
    top: 34px;
    left: 25px;
  }
  .mapPoint {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 50px;
    left: 28px;
    border-radius: 50%;
    border: 2px solid white;
    box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.2);
  }
}

.bmovebox {
  width: 362px;
  padding: 2px 10px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  white-space: nowrap;
  /* 规定文本是否折行 */
  overflow: hidden;
  /* 规定超出内容宽度的元素隐藏 */
  text-overflow: ellipsis;
  /* 规定超出的内容文本省略号显示，通常跟上面的属性连用，因为没有上面的属性不会触发超出规定的内容 */
  border-radius: 8px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.12);
}

.sample {
  height: 40px;
  line-height: 20px;
  /* border: 3px solid rgba(224, 32, 32, 0.4); */
  color: #fff;
  /* 	display: flex;
		align-items: left;
		justify-content: space-around;
		flex-direction: column; */
  text-align: center;
  padding: 0px;
  position: absolute;
  top: 10px;
  border-radius: 50%;
  font-weight: 500;
  font-size: 16px;
}
.sample:hover {
  height: 40px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  padding: 0px;
  position: absolute;
  top: 10px;
  border-radius: 50%;
  font-weight: 500;
  font-size: 16px;
  z-index: 99;
}

.sample.active {
  background: rgba(186, 23, 18, 0.75);
  color: #fff;
}

.infoWindow {
  position: absolute;
  width: 350px;
  height: 350px;
  background-color: rgba($color: #ffffff, $alpha: 0.95);
  top: -380px;
  left: -60px;
  border-radius: 8px;
  z-index: 999;
  font-size: 16px;
  color: black;
  padding: 16px;
  text-align: left;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);

  .trangle {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 20px solid white;
    border-right: 20px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 20px solid transparent;
    top: 350px;
    left: 130px;
  }

  .title {
    height: 32px;
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    text-align: left;
    color: #719ff7;
    letter-spacing: 0.8px;
    padding-left: 20px;
    position: relative;

    .star {
      position: absolute;
      width: 20px;
      height: 20px;
      left: -6px;
      top: 0px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(../../../assets/image/land/start.png);

      &.active {
        background-image: url(../../../assets/image/land/start_active.png);
      }
    }

    .landName {
      display: inline-block;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.detailBox {
  font-size: 12px;
  font-weight: 400;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  color: #333333;
  margin-top: 20px;

  .detail {
    display: inline-block;
    width: 48%;
    font-size: 0;
    vertical-align: middle;
    margin: 5px 0;
    position: relative;

    ._label {
      font-size: 12px;
      display: inline-block;
      width: 60px;
      text-align: right;
      vertical-align: middle;
    }

    ._info {
      font-size: 12px;
      display: inline-block;
      width: 90px;
      vertical-align: middle;
      position: relative;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .status {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: greenyellow;
        border-radius: 50%;
      }
    }
  }
}

.btnBox {
  position: absolute;
  right: 16px;
  bottom: 16px;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #333333;
  cursor: pointer;
  font-size: 14px;
}
</style>
