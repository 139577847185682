<template>
  <div class="checkBoxControl" :style="{ width: `${width}px` }">
    <div class="checkBox">
      <CheckItem
        v-for="(item, index) in selfGruop"
        :key="index"
        :color="item.color"
        :label="item.label"
        :name="item.name"
        :checked="item.checked"
        @setValue="setValue"
      ></CheckItem>
    </div>
  </div>
</template>

<script>
import CheckItem from "./CheckItem.vue";
export default {
  name: "checkBoxControl",
  components: {
    CheckItem,
  },
  props: {
    //动态设置高度
    width: {
      type: [Number, String],
      default: 1640,
    },
    group: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      currentValue: [],
      selfGruop: [
        {
          label: "USE_RES",
          name: "住宅",
          checked: false,
          color: "#719FF7",
        },
        {
          label: "USE_COMS",
          name: "商服",
          checked: false,
          color: "#F5C736",
        },
        {
          label: "USE_IND",
          name: "工业",
          checked: false,
          color: "#6FDCB2",
        },
        {
          label: "USE_OTHER",
          name: "其他",
          checked: false,
          color: "#7182A0",
        },
      ],
    };
  },
  mounted() {
    this.updata();
  },
  watch: {
    group(newValue) {
      this.currentValue = newValue;
      this.updata();
    },
    // currentValue(newValue){
    // 	this.change(newValue)
    // }
  },
  methods: {
    updata() {
      this.currentValue = this.group;
      this.selfGruop.forEach((item) => {
        this.currentValue.forEach((value) => {
          if (item.label == value) {
            item.checked = true;
          }
        });
      });
    },
    setValue(res) {

      this.selfGruop.forEach(item=>{
          if(item.label == res.label){
              item.checked = res.checked
          }
      })
      this.$emit('setGruop',this.selfGruop)
    },
  },
};
</script>

<style scoped lang="scss">
.checkBoxControl {
  height: 60px;
  background-color: transparent;
}

.checkBox {
  width: 320px;
  height: 60px;
  position: relative;
  padding: 16px 4px;
  left: 50%;
  top: 4px;
  background-color: white;
  border: 1px solid #aaa;
  transform: translateX(-50%);
}
</style>
